import { VueComponent } from '~/utils/vue-component';
import { Component, Prop } from 'vue-property-decorator';

import style from './IframeBlock.scss';
import { CztWidgets } from '~/utils/views/widgets';
import { Headline } from '~/components/atoms';
import { Align } from '~/components/atoms/headline/Headline';
import {
  IframeBlockInterface,
  IframeFormHeightMessage,
  IframeMessage,
} from './types';

function isIframeMessage(input: any): input is IframeMessage {
  return input && typeof input === 'object' && typeof input.origin === 'string';
}

function isIframeFormHeightMessage(
  input: any
): input is IframeFormHeightMessage {
  if (!isIframeMessage(input)) {
    return false;
  }

  return (
    input.data &&
    typeof input.data === 'object' &&
    typeof input.data.clientHeight === 'number' &&
    input.data.id === 'VCR.Form.Height'
  );
}

const rootClass = 'czt-iframe-block';

const defaultHeight: number = 350;

@Component(style)
export default class IframeBlock extends VueComponent<IframeBlockInterface>
  implements IframeBlockInterface {
  @Prop()
  public anchorId?: string;

  @Prop()
  public anchorName?: string;

  @Prop({ required: true })
  public className!: CztWidgets;

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  @Prop({ default: '' })
  public title!: string;

  @Prop({ type: String, required: true })
  public url!: string;

  @Prop({ type: Number, default: defaultHeight })
  public height!: number;

  @Prop({ default: false, type: Boolean })
  public side!: boolean;

  protected currentHeight: number = defaultHeight;

  public created() {
    this.currentHeight = this.height;
    if (typeof window !== 'undefined') {
      window.addEventListener('message', this.setHeight, false);
    }
  }

  public beforeDestroy() {
    window.removeEventListener('message', this.setHeight);
  }

  public render() {
    const classes: string[] = [rootClass, 'czt-spacer'];
    if (this.isBottomSpacingCollapsed) {
      classes.push('czt-spacer--collapse-bottom');
    }
    if (this.isTopSpacingCollapsed) {
      classes.push('czt-spacer--collapse-top');
    }

    return (
      <div id={this.anchorId} class={classes.join(' ')}>
        <v-container>
          {this.title && (
            <Headline level={this.side ? 4 : 2} align={Align.LEFT} underscore>
              {this.title}
            </Headline>
          )}
          <iframe
            src={this.url}
            title={this.title}
            scrolling='no'
            style={{
              height: `${this.currentHeight}px`,
              minHeight: `${this.currentHeight}px`,
            }}
          >
            Your web-browser does not support iframe.
          </iframe>
        </v-container>
      </div>
    );
  }

  protected setHeight(event: Event): void {
    if (isIframeFormHeightMessage(event)) {
      this.currentHeight = event.data.clientHeight;
    }
  }
}
